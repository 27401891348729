var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bgFFF" },
      [
        _c(
          "el-form",
          {
            attrs: {
              model: _vm.ruleForm,
              "label-width": "165px",
              "label-position": "right",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 50 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("el-form-item", { attrs: { label: "配置 ID:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.id)),
                    ]),
                    _c("el-form-item", { attrs: { label: "状态:" } }, [
                      _vm._v(_vm._s(_vm._f("stateFilter")(_vm.ruleForm.state))),
                    ]),
                    _c("el-form-item", { attrs: { label: "商户名称:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.operationName)),
                    ]),
                    _c("el-form-item", { attrs: { label: "发票平台:" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("channelFilter")(_vm.ruleForm.invoiceChannel)
                        )
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("el-form-item", { attrs: { label: "开票项目:" } }, [
                          _vm._v(_vm._s(_vm.ruleForm.businessType)),
                        ]),
                        _c("el-form-item", { attrs: { label: "计税方式:" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("taxModeFilter")(_vm.ruleForm.taxMode)
                            )
                          ),
                        ]),
                        _c("el-form-item", { attrs: { label: "税率(%):" } }, [
                          _vm._v(_vm._s(_vm.ruleForm.taxRate)),
                        ]),
                        _c("el-form-item", { attrs: { label: "含税标志:" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("includeTaxFilter")(
                                _vm.ruleForm.includeTax
                              )
                            )
                          ),
                        ]),
                        _vm.ruleForm.invoiceChannel === 1
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "征税方式:" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("taxationMethodFilter")(
                                      _vm.ruleForm.taxationMethod
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.ruleForm.invoiceChannel === 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "平台编码:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.username))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "授权码:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.authorizationCode))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求地址:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestUrl))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求码:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestCode))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求函数:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestMethod))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "目标命名空间:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.targetNamespace))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.invoiceChannel === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "登录税号:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.username))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPKEY:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.appKey))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPSECRET:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.appSecret))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求地址:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestUrl))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "加密盐值:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.userSalt))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "接入码:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.password))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "开票点编码:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.pointCode))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "设备类型:" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("deviceTypeFilter")(
                                      _vm.ruleForm.deviceType
                                    )
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.invoiceChannel === 4
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业私钥:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.companyPriKey))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "平台公钥:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.platFormPubKey))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "开票终端标识:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.invoiceTerminalCode))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "机器编号:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.machineCode))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业税号:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.enterpriseTaxCode))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业代码:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.enterpriseCode))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求地址:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestUrl))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.invoiceChannel === 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPKEY:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.appKey))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPSECRET:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.appSecret))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求地址:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestUrl))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "纳税人名称:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.taxpayerName))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "纳税人识别号:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.taxpayerNumber))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.invoiceChannel === 3
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPKEY:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.appKey))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPSECRET:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.appSecret))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求地址:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestUrl))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求令牌:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.token))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "纳税人名称:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.taxpayerName))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "纳税人识别号:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.taxpayerNumber))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求地址:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.requestUrl))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "不动产地址:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.address))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否跨市:" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("crossCityFlagFilter")(
                                      _vm.ruleForm.crossCityFlag
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "租赁时间:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.rentDate))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "产权证书/不动产权证书:" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.ruleForm.realPropertyCertificate)
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "面积单位:" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("unitFilter")(_vm.ruleForm.unit)
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "分机号:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.extensionNumber))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _vm.ruleForm.invoiceChannel === 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "纳税人名称:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.taxpayerName))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "纳税人识别号:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.taxpayerNumber))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("el-form-item", { attrs: { label: "销货方名称:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.sellerName)),
                    ]),
                    _c("el-form-item", { attrs: { label: "销货方识别号:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.sellerTaxpayerNumber)),
                    ]),
                    _c("el-form-item", { attrs: { label: "销货方地址:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.sellerAddress)),
                    ]),
                    _c("el-form-item", { attrs: { label: "销货方电话:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.sellerPhoneNumber)),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销货方银行名称:" } },
                      [_vm._v(_vm._s(_vm.ruleForm.sellerBank))]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "销货方银行账号:" } },
                      [_vm._v(_vm._s(_vm.ruleForm.sellerBankAccount))]
                    ),
                    _c("el-form-item", { attrs: { label: "收款人:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.payee)),
                    ]),
                    _c("el-form-item", { attrs: { label: "复核:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.auditor)),
                    ]),
                    _c("el-form-item", { attrs: { label: "开票人:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.drawer)),
                    ]),
                    _c("el-form-item", { attrs: { label: "最小开票金额:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.minimumAmount)),
                    ]),
                    _c("el-form-item", { attrs: { label: "最大开票金额:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.maximumAmount)),
                    ]),
                    _vm.ruleForm.invoiceChannel === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否开启票量提醒:" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("needRemindFilter")(
                                      _vm.ruleForm.needRemind
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm.ruleForm.deviceType === 0
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "服务器编号:" } },
                                  [_vm._v(_vm._s(_vm.ruleForm.serverNumber))]
                                )
                              : _vm._e(),
                            _vm.ruleForm.deviceType === 0
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "开票终端标识:" } },
                                  [_vm._v(_vm._s(_vm.ruleForm.terminalId))]
                                )
                              : _vm._e(),
                            _vm.ruleForm.deviceType === 1
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "税控盘口令:" } },
                                  [_vm._v(_vm._s(_vm.ruleForm.deviceToken))]
                                )
                              : _vm._e(),
                            _vm.ruleForm.deviceType === 1
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "税务数字证书密码:" } },
                                  [_vm._v(_vm._s(_vm.ruleForm.devicePassword))]
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "提醒票量:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.remindTicketVolume))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "告警票量:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.warningTicketVolume))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "短信通知:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.messageReceiver))]
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "邮件通知:" } },
                              [_vm._v(_vm._s(_vm.ruleForm.emailReceiver))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("el-form-item", { attrs: { label: "最后操作人:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.operateName)),
                    ]),
                    _c("el-form-item", { attrs: { label: "最后修改时间:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.updateTime)),
                    ]),
                    _c("el-form-item", { attrs: { label: "初始创建时间:" } }, [
                      _vm._v(_vm._s(_vm.ruleForm.createTime)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }