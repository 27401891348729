<template>
  <div>
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button @click="$router.go(-1)">返回</el-button>
    </div>
    <!--主体内容-->
    <div class="bgFFF">
      <el-form :model="ruleForm" label-width="165px" label-position="right">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="配置 ID:">{{ ruleForm.id }}</el-form-item>
            <el-form-item label="状态:">{{ ruleForm.state | stateFilter }}</el-form-item>
            <el-form-item label="商户名称:">{{ ruleForm.operationName }}</el-form-item>
            <el-form-item label="发票平台:">{{ ruleForm.invoiceChannel | channelFilter }}</el-form-item>
            <div class="content">
              <el-form-item label="开票项目:">{{ ruleForm.businessType }}</el-form-item>
              <el-form-item label="计税方式:">{{ ruleForm.taxMode| taxModeFilter }}</el-form-item>
              <el-form-item label="税率(%):">{{ ruleForm.taxRate }}</el-form-item>
              <el-form-item label="含税标志:">{{ ruleForm.includeTax | includeTaxFilter }}</el-form-item>
              <el-form-item v-if="ruleForm.invoiceChannel === 1" label="征税方式:">{{ ruleForm.taxationMethod | taxationMethodFilter }}</el-form-item>
            </div>
            <!-- 航信 -->
            <div v-if="ruleForm.invoiceChannel === 0">
              <el-form-item label="平台编码:">{{ ruleForm.username }}</el-form-item>
              <el-form-item label="授权码:">{{ ruleForm.authorizationCode }}</el-form-item>
              <el-form-item label="请求地址:">{{ ruleForm.requestUrl }}</el-form-item>
              <el-form-item label="请求码:">{{ ruleForm.requestCode }}</el-form-item>
              <el-form-item label="请求函数:">{{ ruleForm.requestMethod }}</el-form-item>
              <el-form-item label="目标命名空间:">{{ ruleForm.targetNamespace }}</el-form-item>
            </div>
            <!-- 百旺 -->
            <div v-if="ruleForm.invoiceChannel === 1">
              <el-form-item label="登录税号:">{{ ruleForm.username }}</el-form-item>
              <el-form-item label="APPKEY:">{{ ruleForm.appKey }}</el-form-item>
              <el-form-item label="APPSECRET:">{{ ruleForm.appSecret }}</el-form-item>
              <el-form-item label="请求地址:">{{ ruleForm.requestUrl }}</el-form-item>
              <el-form-item label="加密盐值:">{{ ruleForm.userSalt }}</el-form-item>
              <el-form-item label="接入码:">{{ ruleForm.password }}</el-form-item>
              <el-form-item label="开票点编码:">{{ ruleForm.pointCode }}</el-form-item>
              <el-form-item label="设备类型:">{{ ruleForm.deviceType | deviceTypeFilter }}</el-form-item>
            </div>
            <!-- 百旺云 -->
            <div v-if="ruleForm.invoiceChannel === 4">
              <el-form-item label="企业私钥:">{{ ruleForm.companyPriKey }}</el-form-item>
              <el-form-item label="平台公钥:">{{ ruleForm.platFormPubKey }}</el-form-item>
              <el-form-item label="开票终端标识:">{{ ruleForm.invoiceTerminalCode }}</el-form-item>
              <el-form-item label="机器编号:">{{ ruleForm.machineCode }}</el-form-item>
              <el-form-item label="企业税号:">{{ ruleForm.enterpriseTaxCode }}</el-form-item>
              <el-form-item label="企业代码:">{{ ruleForm.enterpriseCode }}</el-form-item>
              <el-form-item label="请求地址:">{{ ruleForm.requestUrl }}</el-form-item>
            </div>
            <!-- 航信诺诺 诺诺数电 -->
            <div v-if="ruleForm.invoiceChannel === 2">
              <el-form-item label="APPKEY:">{{ ruleForm.appKey }}</el-form-item>
              <el-form-item label="APPSECRET:">{{ ruleForm.appSecret }}</el-form-item>
              <el-form-item label="请求地址:">{{ ruleForm.requestUrl }}</el-form-item>
               <el-form-item label="纳税人名称:">{{ ruleForm.taxpayerName }}</el-form-item>
              <el-form-item label="纳税人识别号:">{{ ruleForm.taxpayerNumber }}</el-form-item>
            </div>
            <!-- 诺诺数电 -->
            <div v-if="ruleForm.invoiceChannel === 3">
              <el-form-item label="APPKEY:">{{ ruleForm.appKey }}</el-form-item>
              <el-form-item label="APPSECRET:">{{ ruleForm.appSecret }}</el-form-item>
              <el-form-item label="请求地址:">{{ ruleForm.requestUrl }}</el-form-item>
              <el-form-item label="请求令牌:">{{ ruleForm.token }}</el-form-item>
               <el-form-item label="纳税人名称:">{{ ruleForm.taxpayerName }}</el-form-item>
              <el-form-item label="纳税人识别号:">{{ ruleForm.taxpayerNumber }}</el-form-item>
              <el-form-item label="请求地址:">{{ ruleForm.requestUrl }}</el-form-item>
              <el-form-item label="不动产地址:">{{ ruleForm.address }}</el-form-item>
              <el-form-item label="是否跨市:">{{ ruleForm.crossCityFlag | crossCityFlagFilter }}</el-form-item>
              <el-form-item label="租赁时间:">{{ ruleForm.rentDate }}</el-form-item>
               <el-form-item label="产权证书/不动产权证书:">{{ ruleForm.realPropertyCertificate }}</el-form-item>
              <el-form-item label="面积单位:">{{ ruleForm.unit | unitFilter }}</el-form-item>
              <el-form-item label="分机号:">{{ ruleForm.extensionNumber }}</el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <!-- 航信 -->
            <div v-if="ruleForm.invoiceChannel === 0">
              <el-form-item label="纳税人名称:">{{ ruleForm.taxpayerName }}</el-form-item>
              <el-form-item label="纳税人识别号:">{{ ruleForm.taxpayerNumber }}</el-form-item>
            </div>
            <el-form-item label="销货方名称:">{{ ruleForm.sellerName }}</el-form-item>
            <el-form-item label="销货方识别号:">{{ ruleForm.sellerTaxpayerNumber }}</el-form-item>
            <el-form-item label="销货方地址:">{{ ruleForm.sellerAddress }}</el-form-item>
            <el-form-item label="销货方电话:">{{ ruleForm.sellerPhoneNumber }}</el-form-item>
            <el-form-item label="销货方银行名称:">{{ ruleForm.sellerBank }}</el-form-item>
            <el-form-item label="销货方银行账号:">{{ ruleForm.sellerBankAccount }}</el-form-item>
            <el-form-item label="收款人:">{{ ruleForm.payee }}</el-form-item>
            <el-form-item label="复核:">{{ ruleForm.auditor }}</el-form-item>
            <el-form-item label="开票人:">{{ ruleForm.drawer }}</el-form-item>
            <el-form-item label="最小开票金额:">{{ ruleForm.minimumAmount }}</el-form-item>
            <el-form-item label="最大开票金额:">{{ ruleForm.maximumAmount }}</el-form-item>
            <!-- 百旺 -->
            <div v-if="ruleForm.invoiceChannel === 1">
              <el-form-item label="是否开启票量提醒:">{{ ruleForm.needRemind | needRemindFilter }}</el-form-item>
              <el-form-item v-if="ruleForm.deviceType === 0" label="服务器编号:">{{ ruleForm.serverNumber }}</el-form-item>
              <el-form-item v-if="ruleForm.deviceType === 0" label="开票终端标识:">{{ ruleForm.terminalId }}</el-form-item>
              <el-form-item v-if="ruleForm.deviceType === 1" label="税控盘口令:">{{ ruleForm.deviceToken }}</el-form-item>
              <el-form-item v-if="ruleForm.deviceType === 1" label="税务数字证书密码:">{{ ruleForm.devicePassword }}</el-form-item>
              <el-form-item label="提醒票量:">{{ ruleForm.remindTicketVolume }}</el-form-item>
              <el-form-item label="告警票量:">{{ ruleForm.warningTicketVolume }}</el-form-item>
              <el-form-item label="短信通知:">{{ ruleForm.messageReceiver }}</el-form-item>
              <el-form-item label="邮件通知:">{{ ruleForm.emailReceiver }}</el-form-item>
            </div>
            <el-form-item label="最后操作人:">{{ ruleForm.operateName }}</el-form-item>
            <el-form-item label="最后修改时间:">{{ ruleForm.updateTime }}</el-form-item>
            <el-form-item label="初始创建时间:">{{ ruleForm.createTime }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  filters: {
    stateFilter (val) {
      const arr = ['已保存', '已启用', '已停用']
      return arr[val]
    },
    channelFilter (val) {
      const arr = ['航信', '百旺', "航信诺诺", "诺诺数电", "百旺云"]
      return arr[val]
    },
    taxModeFilter (val) {
      const arr = ['一般纳税人', '小规模纳税人']
      return arr[val]
    },
    includeTaxFilter (val) {
      const arr = ['不含税', '含税']
      return arr[val]
    },
    taxationMethodFilter (val) {
      const arr = ['普通征税', '', '差额征税']
      return arr[val]
    },
    deviceTypeFilter (val) {
      const arr = ['税控服务', '税控盘']
      return arr[val]
    },
    needRemindFilter (val) {
      const arr = ['否', '是']
      return arr[val]
    },
    crossCityFlagFilter (val) {
      const arr = ['否', '是']
      return arr[val]
    },
    unitFilter (val) {
      const arr = ['平方千米', '平方米', '公顷', '亩', 'hm²', 'km²', 'm²']
      return arr[val - 1]
    }
  },
  data () {
    return {
      ruleForm: {
        id: '',
        state: ''
      },
      invoiceSettingId: this.$route.query.invoiceSettingId
    }
  },
  mounted() {
    this.getFormDetail()
  },
  methods: {
    // 获取发票配置详情
    getFormDetail () {
      const opt = {
        url: '/acb/2.0/invoiceSetting/getById',
        method: 'get',
        data: { invoiceSettingId: this.invoiceSettingId },
        success: res => {
          const value = res.value
          this.ruleForm.id = value.id
          this.ruleForm.state = value.state
          this.ruleForm.operationName = value.operationName
          this.ruleForm.invoiceChannel = value.invoiceChannel
          this.ruleForm.businessType = value.invoiceSettingItems[0].itemName
          this.ruleForm.taxMode = value.invoiceSettingItems[0].taxMode + ''
          this.ruleForm.taxRate = value.invoiceSettingItems[0].taxRate + ''
          this.ruleForm.includeTax = value.invoiceSettingItems[0].includeTax + ''
          this.ruleForm.taxationMethod = value.invoiceSettingItems[0].taxationMethod
          this.ruleForm.username = value.expand.username
          this.ruleForm.authorizationCode = value.expand.authorizationCode
          this.ruleForm.requestUrl = value.expand.requestUrl
          this.ruleForm.requestCode = value.expand.requestCode
          this.ruleForm.requestMethod = value.expand.requestMethod
          this.ruleForm.targetNamespace = value.expand.targetNamespace
          this.ruleForm.appKey = value.expand.appKey
          this.ruleForm.appSecret = value.expand.appSecret
          this.ruleForm.userSalt = value.expand.userSalt
          this.ruleForm.password = value.expand.password
          this.ruleForm.pointCode = value.expand.pointCode
          this.ruleForm.deviceType = value.expand.deviceType * 1
          this.ruleForm.taxpayerName = value.expand.taxpayerName
          this.ruleForm.taxpayerNumber = value.expand.taxpayerNumber
          this.ruleForm.sellerName = value.sellerName
          this.ruleForm.sellerTaxpayerNumber = value.sellerTaxpayerNumber
          this.ruleForm.sellerAddress = value.sellerAddress
          this.ruleForm.sellerPhoneNumber = value.sellerPhoneNumber
          this.ruleForm.sellerBank = value.sellerBank
          this.ruleForm.sellerBankAccount = value.sellerBankAccount
          this.ruleForm.payee = value.payee
          this.ruleForm.auditor = value.auditor
          this.ruleForm.drawer = value.drawer
          this.ruleForm.minimumAmount = value.minimumAmount
          this.ruleForm.maximumAmount = value.maximumAmount
          this.ruleForm.needRemind = value.expand.needRemind
          this.ruleForm.serverNumber = value.expand.serverNumber
          this.ruleForm.terminalId = value.expand.terminalId
          this.ruleForm.deviceToken = value.expand.deviceToken
          this.ruleForm.devicePassword = value.expand.devicePassword
          this.ruleForm.remindTicketVolume = value.expand.remindTicketVolume
          this.ruleForm.warningTicketVolume = value.expand.warningTicketVolume
          this.ruleForm.messageReceiver = value.expand.messageReceiver
          this.ruleForm.emailReceiver = value.expand.emailReceiver
          this.ruleForm.operateName = value.operateName
          this.ruleForm.updateTime = value.updateTime
          this.ruleForm.createTime = value.createTime

          if (value.invoiceChannel == 3) {
            this.ruleForm.address = value.expand.realPropertyAddress + value.expand.detailAddress
            this.ruleForm.crossCityFlag = value.expand.crossCityFlag
            this.ruleForm.rentDate = `${value.expand.rentStartDate}至${value.expand.rentEndDate}`
            this.ruleForm.realPropertyCertificate = value.expand.realPropertyCertificate
            this.ruleForm.unit = value.expand.unit
            this.ruleForm.extensionNumber = value.expand.extensionNumber
            this.ruleForm.token = value.expand.token
          }

          if (value.invoiceChannel == 4) {
            this.ruleForm.companyPriKey = value.expand.companyPriKey
            this.ruleForm.platFormPubKey = value.expand.platFormPubKey
            this.ruleForm.enterpriseCode = value.expand.enterpriseCode
            this.ruleForm.invoiceTerminalCode = value.expand.invoiceTerminalCode
            this.ruleForm.machineCode = value.expand.machineCode
            this.ruleForm.enterpriseTaxCode = value.expand.enterpriseTaxCode
          }
        }
      }
      this.$request(opt)
    }
  }
}
</script>

<style lang="stylus" scoped>
.breadcrumb
  height 35px
  text-align right
  margin-bottom 20px
.content
  padding 20px 0 1px
  margin-bottom 20px
>>>.el-form-item__content
  word-break break-all
</style>
